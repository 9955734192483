import TYPES from './types';
import COMMON_TYPES from '../../../actions/types';
import { showNotificationLayer } from '../../../actions/notification';
import { remove as d } from '../../../api/common';

const loadingPost = () => ({ type: COMMON_TYPES.formPostWatingResponse });

export const remove = params => {
  return dispatch => {
    dispatch(loadingPost());

    d(params.formParams.id, 'escritorio.php')
      .then(() => {
        dispatch(loadingPost());
        dispatch(toggleFormDeleteLayer());

        dispatch({
          type: TYPES.deleteItemListingManagerSuccess,
          payload: params.formParams,
        });

        dispatch(showNotificationLayer(`O escritório foi removido!`));
      })
      .catch(responseFail => {
        dispatch(loadingPost());

        dispatch({
          type: COMMON_TYPES.formPostResultError,
          payload: responseFail.response.data,
        });
      })
  }
}

export const toggleFormDeleteLayer = params => {
  if(params && params.id) {
    return [
      { type: COMMON_TYPES.toggleLayer, payload: 'delete' },
      { type: COMMON_TYPES.formInitializeValues, payload: params },
    ];
  }

  return [
    { type: COMMON_TYPES.toggleLayer, payload: null },
    { type: COMMON_TYPES.formClearValues },
  ];
};
