import { combineReducers } from 'redux';
import dashboardReducer from './dashboard';
import searchResultReducer from './search-result';
import formSearchReducer from './form-search';

const manifestationReducer = combineReducers({
  dashboard: dashboardReducer,
  searchResult: searchResultReducer,
  formSearch: formSearchReducer,
});

export default manifestationReducer;
