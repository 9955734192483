import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ResponsiveContext, Layer, Box, Button } from 'grommet';
import { FormClose } from 'grommet-icons';

import { Placeholder } from '../../../../components';
import { SimpleDetail, TagStatus, Hr, Description, TitleDetail } from '../../../../components/detail';

import { formattedDateTime, mask } from '../../../../utils';
import visibleWidth from '../../../../constants/layer-width';

import { toggleDetailsLayer } from '../../actions/details';

class DetailsManifestation extends Component {
  render() {
    const {
      detail,
      getDetailError,
      toggleLayer,

      toggleDetailsLayer,
    } = this.props;


    if(!toggleLayer || toggleLayer !== 'details') {
      return null;
    }

    return (
      <ResponsiveContext.Consumer>
        {size => (
          <Layer
            responsive={false}
            full='vertical'
            position='left'
            onClickOutside={toggleDetailsLayer}
            onEsc={toggleDetailsLayer}
          >
            <Box
              fill='vertical'
              width={visibleWidth[size]}
              pad={{
                top: size === 'small' ? 'xlarge' : 'large',
                bottom: size === 'small' ? 'large' : null,
              }}
              overflow='auto'
              flex
            >
              {getDetailError ? (
                <Placeholder
                  title='Ops! Algo deu errado :('
                  message='Estamos constrangidos por isso.'
                  button={{
                    label: 'Fechar',
                    action: toggleDetailsLayer,
                  }}
                />
              ) : null}

              {!detail && !getDetailError ? (
                <Placeholder
                  title='Carregando...'
                  message='Aguarde que estamos preparando as coisas :)'
                />
              ) : null}

              {detail ? (
                <>
                  <Box
                    direction='row'
                    justify='between'
                    align='baseline'
                    margin={{ horizontal: 'large' }}
                  >
                    <TitleDetail value='Detalhes da manifestação' />

                    <Button
                      plain
                      title='Fechar'
                      icon={<FormClose />}
                      onClick={toggleDetailsLayer}
                    />
                  </Box>


                  <Box flex overflow='auto'>
                    <SimpleDetail
                      title='Protocolo'
                      value={detail.protocol}
                    />

                    <SimpleDetail
                      title='Data de recebimento'
                      value={formattedDateTime(detail.createdAt, 'ddd, DD MMM YYYY')}
                    />

                    <SimpleDetail
                      title='Tipo'
                      value={detail.typeName}
                    />

                    <TagStatus
                      title='Status'
                      value={detail.statusName}
                      color={detail.statusColor}
                    />

                    <Hr />

                    <SimpleDetail
                      title='Nome'
                      value={detail.name}
                    />

                    {detail.individualRegistration && <SimpleDetail
                      title='CPF'
                      value={mask(detail.individualRegistration, '###.###.###-##')}
                    />}

                    <SimpleDetail
                      title='E-mail'
                      value={detail.contacts.email}
                    />

                    <SimpleDetail
                      title='Telefone'
                      value={mask(detail.contacts.telephone, '(##) ####-#####')}
                    />

                    <Hr />

                    <SimpleDetail
                      title='Cidade'
                      value={detail.location.city}
                    />

                    <SimpleDetail
                      title='Bairro'
                      value={detail.location.district}
                    />

                    <Hr />

                    <Description
                      title='Descrição'
                      value={detail.description}
                    />
                  </Box>
                </>
              ) : null}
            </Box>
          </Layer>
        )}
      </ResponsiveContext.Consumer>
    );
  }
}

const mapStateToProps = ({ layerDetailsReducer, layerReducer }) => ({
  detail: layerDetailsReducer.detail,
  getDetailError: layerDetailsReducer.getDetailError,

  toggleLayer: layerReducer.toggleLayer,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    toggleDetailsLayer,
  }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DetailsManifestation);
