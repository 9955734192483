import TYPES from '../actions/types';

const INITIAL_STATE = {
  templateList: [],
  templateDetails: {},
  creditCommunication: null,
};

export default (state = INITIAL_STATE, action) => {
  switch(action.type) {
    case TYPES.templateList:
      return {
        ...state,
        templateList: action.payload,
      }

    case TYPES.templateDetails:
      return {
        ...state,
        templateDetails: action.payload,
      }

    case TYPES.creditCommunication:
      return {
        ...state,
        creditCommunication: action.payload,
      }

    case TYPES.clearFormCommunication:
      return {
        ...state,
        ...INITIAL_STATE,
      }

    default:
      return state;
  }
}
