import { combineReducers } from 'redux';
import searchResultReducer from './search-result';
import formSearchReducer from './form-search';

const expenseReducer = combineReducers({
  searchResult: searchResultReducer,
  formSearch: formSearchReducer,
});

export default expenseReducer;
