const buidMessage = ({ template, values }) => {
  return template?.replace(/{{(.*?)}}/g, (p1, p2) =>
    values[p1] || values[p2]
  );
};

const firstVoterName = ({ voterName }) => {
  const names = voterName?.split(' ');
  return names ? names[0] : null;
};

export default object => {
  const {
    telephone,
    voterName,

    template,
    voterId,
    typeId,
    categoryId,
    templateId,

    profileName,
    profilePhone,
  } = object;

  return {
    voterId: parseInt(voterId),
    typeId: parseInt(typeId),
    categoryId: parseInt(categoryId),
    templateId: parseInt(templateId),

    telephone,

    message: buidMessage({
      template,
      values: {
        voterName: firstVoterName({ voterName }),
        profileName,
        profilePhone,
      }
    }),
  };
};
