const updateRow = ({ object, key, items }) => {
  const {
    description,
    statusColor,
    statusName: status = '',
    voter: { name: electorName = '' },
  } = object;

  return items.map(item =>
    item.id === key ? { ...item, description, status, statusColor, electorName } : item
  );
}

export default updateRow;
