import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ResponsiveContext, Layer, Box, Button } from 'grommet';
import { FormClose } from 'grommet-icons';

import { Placeholder } from '../../../../components';
import { SimpleDetail, Hr, TagStatus, Description, TitleDetail } from '../../../../components/detail';

import { resolveValue, formattedDateTime } from '../../../../utils';

import visibleWidth from '../../../../constants/layer-width';
import selectVoterStatusOptions from '../../../../constants/select-voter-status-options';

import { toggleDetailsLayer } from '../../actions/details';

class DetailsVoter extends Component {
  render() {
    const {
      detail,
      getDetailError,
      toggleLayer,

      toggleDetailsLayer,
    } = this.props;

    if(!toggleLayer || toggleLayer !== 'details')
      return null;


    return (
      <ResponsiveContext.Consumer>
        {size => (
          <Layer
            responsive={false}
            full='vertical'
            position='left'
            onClickOutside={toggleDetailsLayer}
            onEsc={toggleDetailsLayer}
          >
            <Box
              fill='vertical'
              width={visibleWidth[size]}
              pad={{
                top: size === 'small' ? 'xlarge' : 'large',
                bottom: size === 'small' ? 'large' : null,
              }}
              overflow='auto'
              flex
            >
              {getDetailError ? (
                <Placeholder
                  title='Ops! Algo deu errado :('
                  message='Estamos constrangidos por isso.'
                  button={{
                    label: 'Fechar',
                    action: toggleDetailsLayer,
                  }}
                />
              ) : null}

              {!detail && !getDetailError ? (
                <Placeholder
                  title='Carregando...'
                  message='Aguarde que estamos preparando as coisas :)'
                />
              ) : null}

              {detail ? (
                <>
                  <Box
                    direction='row'
                    justify='between'
                    align='baseline'
                    margin={{ horizontal: 'large' }}
                  >
                    <TitleDetail value={`Detalhes do eleitor ${detail.name}`} />

                    <Button
                      plain
                      title='Fechar'
                      icon={<FormClose />}
                      onClick={toggleDetailsLayer}
                    />
                  </Box>


                  <Box flex overflow='auto'>
                    {detail.nickName && (
                      <SimpleDetail
                        title='Apelido'
                        value={detail.nickName}
                      />
                    )}

                    {detail.dateOfBirth && (
                      <SimpleDetail
                        title='Data de nascimento'
                        value={formattedDateTime(detail.dateOfBirth)}
                      />
                    )}

                    <TagStatus
                      title='Status'
                      value={detail.status ? 'Ativo' : 'Inativo'}
                      color={detail.status ? 'status-ok' : 'status-error'}
                    />

                    {detail.maritalStatusName && (
                      <SimpleDetail
                        title='Estado Civil'
                        value={detail.maritalStatusName}
                      />
                    )}

                    {detail.professionName && (
                      <SimpleDetail
                        title='Profissão'
                        value={detail.professionName}
                      />
                    )}

                    {detail.individualRegistration && (
                      <SimpleDetail
                        title='CPF'
                        value={detail.individualRegistration}
                      />
                    )}

                    <Hr />

                    {detail.withLocation && (
                      <>
                        <SimpleDetail
                          title='Endereço'
                          value={detail.address.address}
                        />

                        <SimpleDetail
                          title='Número/complemento'
                          value={`${detail.address.number} ${detail.address.addressAdditional}`}
                        />

                        <SimpleDetail
                          title='Bairro'
                          value={detail.address.districtName}
                        />

                        <SimpleDetail
                          title='Cidade'
                          value={detail.address.cityName}
                        />

                        <SimpleDetail
                          title='Estado'
                          value={detail.address.stateName}
                        />

                        <SimpleDetail
                          title='CEP'
                          value={detail.address.zipCode}
                        />

                        <Hr />
                      </>
                    )}

                    {detail.note && (
                      <>
                        <Description
                          title='Observação'
                          value={detail.note}
                        />

                        <Hr />
                      </>
                    )}

                    {detail.telephones && detail.telephones.length > 0 &&
                      <>
                        {
                          detail.telephones.map((phone, index) => (
                            <SimpleDetail
                              key={index}
                              title={phone.typeName ?? 'Não informado'}
                              value={phone.number}
                            />
                          ))
                        }

                        <Hr />
                      </>
                    }

                    {detail.email && (
                      <>
                        <SimpleDetail
                          title='E-mail'
                          value={detail.email}
                        />

                        <Hr />
                      </>
                    )}

                    <TagStatus
                      title='Status do voto'
                      value={resolveValue(selectVoterStatusOptions, {
                          searchBy: detail.voteStatusId,
                          searchByProp: 'id',
                          returnProp: 'name'
                        })
                      }
                      color={resolveValue(selectVoterStatusOptions, {
                        searchBy: detail.voteStatusId,
                        searchByProp: 'id',
                        returnProp: 'color'
                      })
                      }
                    />

                    {detail.voterRegistration &&
                      <SimpleDetail
                        title='Título de eleitor'
                        value={detail.voterRegistration}
                      />
                    }

                    {detail.electoralZone &&
                      <SimpleDetail
                        title='Zona eleitoral'
                        value={detail.electoralZone}
                      />
                    }

                    {detail.votingSection &&
                      <SimpleDetail
                        title='Seção eleitoral'
                        value={detail.votingSection}
                      />
                    }

                    {detail.schoolName && (
                      <SimpleDetail
                        title='Local de votação'
                        value={detail.schoolName}
                      />
                    )}
                  </Box>
                </>
              ) : null}
            </Box>
          </Layer>
        )}
      </ResponsiveContext.Consumer>
    );

  }
}

const mapStateToProps = ({ layerDetailsReducer, layerReducer }) => ({
  detail: layerDetailsReducer.detail,
  getDetailError: layerDetailsReducer.getDetailError,

  toggleLayer: layerReducer.toggleLayer,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    toggleDetailsLayer,
  }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(DetailsVoter);
