import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ResponsiveContext, Layer, Text, Box, Anchor, Button, RadioButtonGroup, Select } from 'grommet';
import { FormClose } from 'grommet-icons';

import { Placeholder, ErrorForm } from '../../../../components';
import { TitleDetail } from '../../../../components/detail';
import { TitleField, DescriptionField } from '../../../../components/form';
import { MessageTemplate } from '../message-template/message-template';

import visibleWidth from '../../../../constants/layer-width';

import selectCampaignTypeOptions from '../../../../constants/select-campaign-type-options';
import selectCommunicationCategoryOptions from '../../../../constants/select-communication-category';

import { changeValues } from '../../../../actions/form';
import {
  toggleFormShortMessageLayer,
  sendShortMessage,
  searchTemplates,
  searchTemplateDetails,
  searchCredit,
} from '../../actions/form-short-message';

import { formattedDateTime } from '../../../../utils';

class FormShortMessage extends Component {
  componentDidUpdate(prevProps) {
    const {
      categoryId,
      typeId,
      templateId,

      searchTemplates,
      searchTemplateDetails,
      searchCredit,

      changeValues,
    } = this.props;

    if(prevProps.categoryId !== categoryId || prevProps.typeId !== typeId)
    {
      if(categoryId && typeId) {
        searchTemplates({ typeId, categoryId });
      }

      changeValues({
        fieldName: 'templateId', value: null,
      }, 'formChangeValues');
    }

    if(prevProps.typeId !== typeId) {
      searchCredit({ typeId });
    }

    if(prevProps.templateId !== templateId && templateId !== null) {
      searchTemplateDetails({ id: templateId });
    }
  };

  callbackPostSuccess = () => {
    const {
      toggleFormShortMessageLayer,
    } = this.props;

    toggleFormShortMessageLayer();
  };

  handlePost = formParams => {
    const {
      sendShortMessage,
    } = this.props;

    sendShortMessage({ formParams, callbackSuccess: this.callbackPostSuccess });
  };

  enabledSaveButton = () => {
    const {
      typeId,
      categoryId,
      templateId,
      telephone,
    } = this.props;

    return typeId && categoryId && templateId && telephone
  };

  render() {
    const {
      voterDetails,
      profile,
      history,

      getDetailError,

      typeId,
      categoryId,
      templateId,
      telephone,

      toggleLayer,
      loadingPostForm,
      postDataError,

      templateOptions,
      templateDetails,
      creditCommunication,

      changeValues,
      toggleFormShortMessageLayer,
    } = this.props;

    if(!toggleLayer || toggleLayer !== 'sendShortMessage')
      return null;


    const acceptCommuniction = voterDetails?.allowCommunication;
    const hasChannelCommunication = voterDetails?.telephones?.length;

    return (
      <ResponsiveContext.Consumer>
        {size => (
          <Layer
            responsive={false}
            full='vertical'
            position='left'
            onClickOutside={toggleFormShortMessageLayer}
            onEsc={toggleFormShortMessageLayer}
          >
            <Box
              fill='vertical'
              width={visibleWidth[size]}
              pad={{ top: 'large', bottom: 'xsmall' }}
              overflow='auto'
              flex
            >
              {getDetailError ? (
                <Placeholder
                  title='Ops! Algo deu errado :('
                  message='Estamos constrangidos por isso.'
                  button={{
                    label: 'Fechar',
                    action: toggleFormShortMessageLayer,
                  }}
                />
              ) : null}

              {!voterDetails && !profile && !history && !getDetailError ? (
                <Placeholder
                  title='Carregando...'
                  message='Aguarde que estamos preparando as coisas :)'
                />
              ) : null}

              {voterDetails && profile && !acceptCommuniction ? (
                <Placeholder
                  title='Ops!'
                  message='Esse eleitor decidiu não receber comunicação por e-mail, SMS ou WhatsApp.'
                  button={{
                    label: 'Fechar',
                    action: toggleFormShortMessageLayer,
                  }}
                />
              ) : null}

              {voterDetails && profile && acceptCommuniction && !hasChannelCommunication ? (
                <Placeholder
                  title='Ops!'
                  message='Esse eleitor não têm telefone cadastrado para receber comunicação.'
                  button={{
                    label: 'Fechar',
                    action: toggleFormShortMessageLayer,
                  }}
                />
              ) : null}

              {voterDetails && profile === null && !getDetailError ? (
                <Placeholder
                  title='Ops!'
                  message='Configure o perfil de exibição antes de utilizar essa funcionalidade.
                           Acesse Configurações > "Perfil de exibição".'
                  button={{
                    label: 'Fechar',
                    action: toggleFormShortMessageLayer,
                  }}
                />
              ) : null}

              {voterDetails && acceptCommuniction && hasChannelCommunication && profile ? (
                <>
                  <Box
                    direction='row'
                    justify='between'
                    align='baseline'
                    margin={{ horizontal: 'large' }}
                  >
                    <TitleDetail value='Comunicação com eleitor' />

                    <Button
                      plain
                      title='Fechar'
                      icon={<FormClose />}
                      onClick={toggleFormShortMessageLayer}
                    />
                  </Box>


                  <Box flex overflow='auto'>
                    {/* Sobre */}
                    <Box
                      gap={ size === 'small' ? 'medium' : 'small' }
                      margin={{
                        vertical: 'small',
                        horizontal: 'large'
                      }}
                      flex={{ shrink: 0 }}
                    >
                      <Text color='dark-2' size='small'>
                        Conecte-se com <u>{voterDetails.name}</u> de forma rápida e eficiente!
                        Acompanhe abaixo seu histórico de interações e fortaleça o relacionamento.
                      </Text>
                    </Box>


                    {/* Histórico */}
                    <Box
                      margin={{
                        vertical: 'small',
                        horizontal: 'large'
                      }}
                      flex={{ shrink: 0 }}
                    >
                      <Box
                        round='small'
                        pad={{ horizontal: 'small', vertical: 'small' }}
                        gap='xxsmall'
                        background='light-1'
                      >
                        {history.length ? history.map(({ id, createdAt, description }) => (
                          <Box
                            key={id}
                            direction='row'
                            justify='between'
                          >
                            <Text size='small' color='dark-3'>{description}</Text>
                            <Text size='small' color='dark-3'>
                              {formattedDateTime(createdAt, 'DD MMM YYYY')}
                            </Text>
                          </Box>
                        )) : <Text size='small' color='dark-3'>Nenhuma comunicação recente.</Text>}
                      </Box>
                    </Box>


                    {/* Categoria comunicação */}
                    <Box
                      margin={{
                        vertical: 'small',
                        horizontal: 'large'
                      }}
                      flex={{ shrink: 0 }}
                      direction={ size === 'small' ? 'column' : 'row' }
                      align='stretch'
                      gap='small'
                    >
                      <Box
                        width={ size === 'small' ? '100vw' : '50vw'}
                        gap='xxsmall'
                      >
                        <TitleField text='Categoria' required />

                        <DescriptionField
                          text='O que deseja comunicar? Aniversário, cadastro, visita...'
                        />
                      </Box>

                      <Box
                        width={ size === 'small' ? '100vw' : '50vw'}
                      >
                        <Select
                          options={selectCommunicationCategoryOptions}
                          value={categoryId ?? ''}
                          labelKey='name'
                          valueKey={{ key: 'id', reduce: true }}
                          placeholder='Selecione uma opção'
                          title='Selecione o meio de envio'
                          onChange={({ value: nextValue }) =>
                            changeValues({ fieldName: 'categoryId', value: nextValue }, 'formChangeValues')
                          }
                        />

                        <ErrorForm errorsList={postDataError.errors} fieldName='categoryId' />
                      </Box>
                    </Box>


                    {/* meio de comunicação */}
                    <Box
                      margin={{
                        vertical: 'small',
                        horizontal: 'large'
                      }}
                      flex={{ shrink: 0 }}
                      direction={ size === 'small' ? 'column' : 'row' }
                      align='stretch'
                      gap='small'
                    >
                      <Box
                        width={ size === 'small' ? '100vw' : '50vw'}
                        gap='xxsmall'
                      >
                        <TitleField text='Enviar por' required />

                        <DescriptionField
                          text='Marque por qual meio a mensagem será enviada. Logo teremos mais opções ;)'
                        />
                      </Box>

                      <Box
                        width={ size === 'small' ? '100vw' : '50vw'}
                      >
                        <Box
                          round='xxsmall'
                          pad={{
                            vertical: 'small',
                          }}
                          gap='xsmall'
                        >
                          <RadioButtonGroup
                            disabled={!categoryId}
                            name='typeId'
                            value={typeId ?? ''}
                            options={
                              selectCampaignTypeOptions
                                .filter(item => item.communication)
                                .map(item => ({
                                    label: item.name,
                                    id: item.id.toString(),
                                    value: item.id.toString(),
                                  })
                                )
                            }
                            onChange={event => {
                              changeValues({ fieldName: 'typeId', value: event.target.value }, 'formChangeValues')
                            }}
                          >
                            {(option, { checked }) => {
                              let background = null;
                              let borderColor = 'dark-5';

                              if(checked) {
                                background = 'brand';
                                borderColor = 'brand';
                              }

                              return (
                                <Box
                                  direction='row'
                                  gap='small'
                                  justify='center'
                                  align='center'
                                >
                                  <Box
                                    width={'20px'}
                                    height={'20px'}
                                    background={background}
                                    round='xxsmall'
                                    border={{
                                      color: borderColor
                                    }}
                                  />

                                  <Text color='dark-2' size='small'>{option.label}</Text>
                                </Box>
                              )
                            }}
                          </RadioButtonGroup>
                        </Box>

                        <ErrorForm errorsList={postDataError.errors} fieldName='typeId' />
                      </Box>
                    </Box>


                    {/* Créditos */}
                    {creditCommunication === 0 && (
                      <Box
                        margin={{
                          vertical: 'small',
                          horizontal: 'large'
                        }}
                        flex={{ shrink: 0 }}
                        align='stretch'
                        gap='small'
                      >
                        <Box
                          pad={{
                            vertical: 'small',
                            horizontal: 'small',
                          }}
                          round='xsmall'
                          background='#fff4dd'
                        >
                          <Text size='small' color='status-warning'>
                            Créditos insuficientes para enviar mensagens por este canal.
                            Acesse a Área do cliente e adquira mais créditos.
                          </Text>
                        </Box>
                      </Box>
                    )}


                    {/* Mensagem para WhatsApp Web */}
                    {parseInt(typeId) === 3 && (
                      <Box
                        margin={{
                          vertical: 'small',
                          horizontal: 'large'
                        }}
                        flex={{ shrink: 0 }}
                        align='stretch'
                        gap='small'
                      >
                        <Box
                          pad={{
                            vertical: 'small',
                            horizontal: 'small',
                          }}
                          round='xsmall'
                          background='#fff4dd'
                        >
                          <Text size='small' color='status-warning'>
                            Abriremos uma aba com o WhatsApp Web. Certifique-se de estar logado no WhatsApp Web.
                          </Text>
                        </Box>
                      </Box>
                    )}


                    {/* Contatos */}
                    <Box
                      margin={{
                        vertical: 'small',
                        horizontal: 'large'
                      }}
                      flex={{ shrink: 0 }}
                      direction={ size === 'small' ? 'column' : 'row' }
                      align='stretch'
                      gap='small'
                    >
                      <Box
                        width={ size === 'small' ? '100vw' : '50vw'}
                        gap='xxsmall'
                      >
                        <TitleField text='Qual telefone' required />

                        <DescriptionField
                          text='Marque para qual telefone a mensagem será enviada.'
                        />
                      </Box>

                      <Box
                        width={ size === 'small' ? '100vw' : '50vw'}
                      >
                        <Box
                          round='xxsmall'
                          pad={{
                            vertical: 'small',
                          }}
                          gap='xsmall'
                        >
                          <RadioButtonGroup
                            disabled={!categoryId}
                            name='telephone'
                            value={telephone ?? ''}
                            options={
                              voterDetails.telephones.map(item => ({
                                label: item.number,
                                id: item.number,
                                value: item.number,
                              }))
                            }
                            onChange={event => {
                              changeValues({ fieldName: 'telephone', value: event.target.value }, 'formChangeValues')
                            }}
                          >
                            {(option, { checked }) => {
                              let background = null;
                              let borderColor = 'dark-5';

                              if(checked) {
                                background = 'brand';
                                borderColor = 'brand';
                              }

                              return (
                                <Box
                                  direction='row'
                                  gap='small'
                                  justify='center'
                                  align='center'
                                >
                                  <Box
                                    width={'20px'}
                                    height={'20px'}
                                    background={background}
                                    round='xxsmall'
                                    border={{
                                      color: borderColor
                                    }}
                                  />

                                  <Text color='dark-2' size='small'>{option.label}</Text>
                                </Box>
                              )
                            }}
                          </RadioButtonGroup>
                        </Box>

                        <ErrorForm errorsList={postDataError.errors} fieldName='telephone' />
                      </Box>
                    </Box>


                    {/* Template Mensagem */}
                    <Box
                      margin={{
                        vertical: 'small',
                        horizontal: 'large'
                      }}
                      direction={ size === 'small' ? 'column' : 'row' }
                      align='stretch'
                      flex={{ shrink: 0 }}
                      gap='small'
                    >
                      <Box
                        width={ size === 'small' ? '100vw' : '50vw' }
                        gap='xxsmall'
                      >
                        <TitleField text='Selecione um modelo' required />

                        <Select
                          options={templateOptions}
                          value={templateId ?? ''}
                          labelKey='name'
                          disabled={!typeId || !categoryId || !telephone}
                          valueKey={{ key: 'id', reduce: true }}
                          placeholder='Selecione uma opção'
                          title={ !typeId || !categoryId ? 'Selecione os demais campos' : 'Selecione um modelo' }
                          onChange={({ value: nextValue }) =>
                            changeValues({ fieldName: 'templateId', value: nextValue }, 'formChangeValues')
                          }
                        />

                        <ErrorForm errorsList={postDataError.errors} fieldName='templateId' />
                      </Box>

                      <Box
                        width={ size === 'small' ? '100vw' : '50vw' }
                        margin={{ top: 'medium' }}
                      >
                        <MessageTemplate
                          type={templateDetails.typeId || null}
                          template={templateDetails.templateShow || null}
                          templateId={templateId}
                          values={{
                            voterName: voterDetails?.name?.split(' ')[0],
                            profileName: profile?.displayName,
                            profilePhone: profile?.displayPhoneNumber,
                          }}
                        />
                      </Box>
                    </Box>
                  </Box>


                  <Box
                    direction='row'
                    pad={{ vertical: 'medium' }}
                    justify='center'
                    align='center'
                    gap='small'
                  >
                    <Box width='xsmall'>
                      <Anchor
                        color='dark-3'
                        label='Cancelar'
                        disabled={ loadingPostForm }
                        onClick={ toggleFormShortMessageLayer }
                      />
                    </Box>

                    <Box width='small'>
                      <Button
                        color='brand'
                        primary
                        label={ !loadingPostForm ? 'Enviar' : 'Enviando...' }
                        disabled={ loadingPostForm || creditCommunication === 0 || !this.enabledSaveButton() }
                        onClick={() =>
                          this.handlePost({
                            telephone,

                            voterId: voterDetails.electorId,
                            voterName: voterDetails.name,

                            profileName: profile.displayName,
                            profilePhone: profile.displayPhoneNumber,

                            typeId: templateDetails.typeId,
                            categoryId: templateDetails.categoryId,
                            templateId: templateDetails.id,

                            template: templateDetails.template,
                            templateVariables: templateDetails.variables,
                          })
                        }
                      />
                    </Box>
                  </Box>
                </>
              ) : null}

            </Box>
          </Layer>
        )}
      </ResponsiveContext.Consumer>
    );
  }
}

const mapStateToProps = ({ votersReducer: { formShortMessage }, layerDetailsReducer, layerReducer, formCreateUpdateCopyGeneric }) => ({
  voterDetails: layerDetailsReducer?.detail?.voter,
  profile: layerDetailsReducer?.detail?.profile,
  history: layerDetailsReducer?.detail?.history,

  getDetailError: layerDetailsReducer.getDetailError,

  typeId: formCreateUpdateCopyGeneric.typeId,
  categoryId: formCreateUpdateCopyGeneric.categoryId,
  templateId: formCreateUpdateCopyGeneric.templateId,
  telephone: formCreateUpdateCopyGeneric.telephone,

  loadingPostForm: formCreateUpdateCopyGeneric.loadingPostForm,
  postDataError: formCreateUpdateCopyGeneric.postDataError,

  templateOptions: formShortMessage.templateList,
  templateDetails: formShortMessage.templateDetails,
  creditCommunication: formShortMessage.creditCommunication,

  toggleLayer: layerReducer.toggleLayer,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    toggleFormShortMessageLayer,
    sendShortMessage,
    searchTemplates,
    searchTemplateDetails,
    searchCredit,
    changeValues,
  }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(FormShortMessage);
