import TYPES from './types';
import COMMON_TYPES from '../../../actions/types';
import { showNotificationLayer } from '../../../actions/notification';
import { changeStatusManifestation } from '../../../api/manifestation';

const loadingPost = () => ({ type: COMMON_TYPES.formPostWatingResponse });

export const changeStatus = params => {
  return dispatch => {
    dispatch(loadingPost());

    changeStatusManifestation(params.formParams)
      .then(response => {
        dispatch(loadingPost());
        dispatch(toggleFormChangeStatusLayer());

        dispatch({
          type: TYPES.updateItemListing,
          payload: response,
        });

        dispatch(
          showNotificationLayer(`O status da manifestação ${response.data.protocol} foi alterado!`)
        );
      })
      .catch(responseFail => {
        dispatch(loadingPost());

        dispatch({
          type: COMMON_TYPES.formPostResultError,
          payload: responseFail.response.data,
        });
      })
  }
};

export const toggleFormChangeStatusLayer = params => {
  if(params && params.id) {
    return [
      { type: COMMON_TYPES.toggleLayer, payload: 'changeStatus' },
      {
        type: COMMON_TYPES.formInitializeValues,
        payload: {
          id: params.id,
          name: params.name,
          protocolNumber: params.protocolNumber,
          currentStatusId: params.statusId,
          statusId: null,
        },
      },
    ];
  }

  return [
    { type: COMMON_TYPES.toggleLayer, payload: null },
    { type: COMMON_TYPES.formClearValues },
  ];
};
