import axiosGlobalInstance from '../http-instance';
import { getCookie, decryptAes } from '../../utils';

export default params => {
  /*
  category=communication
  resourceId=208187
  page=1
  limit=5
  */

  const userLoggedState = getCookie();
  const decipher = decryptAes(userLoggedState);

  return axiosGlobalInstance({
    method: 'GET',
    url: 'resource.history.php',
    params,
    headers: {
      token: decipher?.token?.value,
      action: 'search',
    }
  });
}
