import TYPES from './types';
import COMMON_TYPES from '../../../actions/types';
import * as analytics from '../../../analytics';
import { showNotificationLayer } from '../../../actions/notification';
import { create as c, update as u, details } from '../../../api/common';

const searchDetails = id =>
  details({ id, path: 'legislative.processe.php' })
    .then(response => ({
        type: TYPES.loadDataEdit,
        payload: response,
      })
    )
    .catch(() => ({ type: TYPES.loadingFormManagerEditError }))

const loadingPostManager = () => ({
  type: TYPES.loadingPostFormManager,
});

export const update = params => {
  return dispatch => {
    dispatch(loadingPostManager());

    u(params.formParams, 'legislative.processe.php')
      .then(response => {
        dispatch(loadingPostManager());
        dispatch(hideFormManagerLayer());

        dispatch({
          type: TYPES.updateItemListingManagerSuccess,
          payload: response,
        });

        dispatch(
          showNotificationLayer(`O documento (${response.data.typeName}) foi atualizado!`)
        );
      })
      .catch(responseFail => {
        dispatch(loadingPostManager());

        dispatch({
          type: TYPES.formManagerPostError,
          payload: responseFail.response,
        });
      })
  }
}

export const create = params => {
  return dispatch => {
    dispatch(loadingPostManager());

    c(params.formParams, 'legislative.processe.php')
      .then(response => {
        dispatch(loadingPostManager());

        dispatch(
          showNotificationLayer(`O documento (${response.data.typeName}) foi criado!`)
        );
      })
      .then(() => { params.callbackSuccess(); })
      .catch(responseFail => {
        dispatch(loadingPostManager());

        dispatch({
          type: TYPES.formManagerPostError,
          payload: responseFail.response,
        });
      })
  }
}

export const toggleFormManagerLayer = params => {
  if(params && params.id) {
    //ga
    analytics.registerEvent({
      category: analytics.categories.legislative,
      action: analytics.actions.legislativeEdit,
    });

    return [
      { type: COMMON_TYPES.toggleLayer, payload: 'manager' },
      { type: TYPES.loadingFormManagerEdit },
      searchDetails(params.id),
    ];
  }

  //ga
  analytics.registerEvent({
    category: analytics.categories.legislative,
    action: analytics.actions.legislativeCreate,
  });

  return { type: COMMON_TYPES.toggleLayer, payload: 'manager' }
};

export const hideFormManagerLayer = () => [
  { type: COMMON_TYPES.toggleLayer, payload: null },
  { type: TYPES.clearFormManager }
];
