import { list } from '../../../api/common';
import { downloadCsv } from '../../../components';
import { formattedDateTime } from '../../../utils';
import { showNotificationLayer } from '../../../actions/notification';

export const generateCsv = params =>
  dispatch => {
    dispatch(
      showNotificationLayer('Gerando Excel. Aguarde...')
    );

    list(params, 'manifestation.php')
      .then(({ data: { data } }) => {
        downloadCsv({
          data,
          specifications: {
            protocolNumber: { name: 'Protocolo', transform: (value) => value ?? '' },
            createdAt: { name: 'Data de recebimento', transform: (value) => value ?? '' },
            name: { name: 'Nome', transform: (value) => value ?? '' },
            email: { name: 'E-mail', transform: (value) => value ?? '' },
            telephone: { name: 'Telefone', transform: (value) => value ?? '' },
            typeName: { name: 'Tipo', transform: (value) => value ?? '' },
            statusName: { name: 'Status', transform: (value) => value ?? '' },
          },
          fileName: `listagem-manifestacoes-${formattedDateTime(new Date(), 'DD-MM-YYYY-HH-mm')}.csv`,
        })
      })
      .catch(() =>
        dispatch(
          showNotificationLayer('Ops... não conseguimos gerar o Excel.')
        )
      );
  }
