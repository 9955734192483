import axiosGlobalInstance from '../http-instance';
import { getCookie, decryptAes } from '../../utils';

const sendCommunication = (values) => {
  const userLoggedState = getCookie();
  const decipher = decryptAes(userLoggedState);

  return axiosGlobalInstance({
    method: 'POST',
    url: '/send.communication.php',
    data: values,
    headers: {
      token: decipher?.token?.value,
      action: 'create',
    }
  });
}

export default sendCommunication;
