import COMMON_TYPES from '../../../actions/types';
import { showNotificationLayer } from '../../../actions/notification';
import { deletePhonecall } from '../../../api/phonecall';
import { formatFormDelete } from '../utils';

const loadingPost = () => ({ type: COMMON_TYPES.formPostWatingResponse });

export const remove = params => {
  return dispatch => {
    dispatch(loadingPost());

    deletePhonecall(params.formParams.id)
      .then(() => {
        dispatch(loadingPost());

        dispatch(
          showNotificationLayer(`A ligação LI-${params.formParams.id} foi removida!`)
        );
      })
      .then(() => { params.callbackSuccess(); })
      .catch(responseFail => {
        dispatch(loadingPost());

        dispatch({
          type: COMMON_TYPES.formPostResultError,
          payload: responseFail.response.data,
        });
      })
  }
}

export const toggleFormDeleteLayer = params => {
  if(params && params.id) {
    return [
      { type: COMMON_TYPES.toggleLayer, payload: 'delete' },
      { type: COMMON_TYPES.formInitializeValues, payload: formatFormDelete(params) },
    ];
  }

  return [
    { type: COMMON_TYPES.toggleLayer, payload: null },
    { type: COMMON_TYPES.formClearValues },
  ];
};
