import axiosGlobalInstance from '../http-instance';
import { getCookie, decryptAes } from '../../utils';

export default ({ id, path }) => {
  const formattedParams = { id };

  const userLoggedState = getCookie();
  const decipher = decryptAes(userLoggedState);

  return axiosGlobalInstance({
    method: 'GET',
    url: path,
    params: formattedParams,
    headers: {
      token: decipher?.token?.value,
      action: 'getDetails',
    }
  });
}
