import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ResponsiveContext, Button, Layer, Box, Anchor, RadioButtonGroup } from 'grommet';
import { FormClose } from 'grommet-icons';

import { ErrorForm, Tag } from '../../../../components';
import { TitleField } from '../../../../components/form';
import { SimpleDetail, Hr, TitleDetail } from '../../../../components/detail';

import visibleWidth from '../../../../constants/layer-width';

import { changeStatus, toggleFormChangeStatusLayer } from '../../actions/form-change-status';
import { changeValues } from '../../../../actions/form';

import selectManifestationStatusOptions from '../../../../constants/select-manifestation-status';

class FormChangeStatus extends Component {
  handlePost = formParams => {
    const {
      changeStatus,
    } = this.props;

    changeStatus({ formParams });
  };

  render() {
    const {
      id,
      name,
      protocolNumber,
      statusId,
      currentStatusId,

      toggleLayer,
      loadingPostForm,
      postDataError,

      changeValues,
      toggleFormChangeStatusLayer,
    } = this.props;


    if(!toggleLayer || toggleLayer !== 'changeStatus') {
      return null;
    }

    return (
      <ResponsiveContext.Consumer>
        {size => (
          <Layer
            responsive={false}
            full='vertical'
            position='left'
            onClickOutside={toggleFormChangeStatusLayer}
            onEsc={toggleFormChangeStatusLayer}
          >
            <Box
              fill='vertical'
              width={visibleWidth[size]}
              pad={{ top: 'large', bottom: 'xsmall' }}
              overflow='auto'
              flex
            >
              <Box
                direction='row'
                justify='between'
                align='baseline'
                margin={{ horizontal: 'large' }}
              >
                <TitleDetail value='Trocar status' />

                <Button
                  plain
                  title='Fechar'
                  icon={<FormClose />}
                  onClick={toggleFormChangeStatusLayer}
                />
              </Box>


              <Box flex overflow='auto'>
                <SimpleDetail
                  title='Protocolo'
                  value={protocolNumber}
                  description='Código gerado pelo sistema e compartilhado com o responsável pela manifestação.'
                  width={{
                    title: '50vw',  value: '50vw'
                  }}
                />

                <SimpleDetail
                  title='Nome'
                  value={name}
                  description='Nome do responsável pela manifestação.'
                  width={{
                    title: '50vw',  value: '50vw'
                  }}
                />

                <Hr />

                <Box
                  margin={{
                    vertical: size === 'small' ? 'medium' : 'small',
                    horizontal: 'large'
                  }}
                  flex={{ shrink: 0 }}
                >
                  <TitleField text='Qual será o novo status?' required />

                  <Box
                    round='xxsmall'
                    pad={{
                      vertical: 'small',
                    }}
                    gap='xsmall'
                  >
                    <RadioButtonGroup
                      name='statusId'
                      value={statusId ?? ''}
                      options={
                        selectManifestationStatusOptions.map(item => ({
                          label: item.name,
                          color: item.color,
                          id: item.id.toString(),
                          value: item.id.toString(),
                          disabled: item.id === currentStatusId,
                        }))
                      }
                      onChange={event => {
                        changeValues({ fieldName: 'statusId', value: event.target.value }, 'formChangeValues')
                      }}
                    >
                      {({ label, color }, { checked }) => {
                        let background = null;
                        let borderColor = 'dark-5';

                        if(checked) {
                          background = 'brand';
                          borderColor = 'brand';
                        }

                        return (
                          <Box
                            direction='row'
                            gap='small'
                            justify='center'
                            align='center'
                          >
                            <Box
                              width={'20px'}
                              height={'20px'}
                              background={background}
                              round='xxsmall'
                              border={{
                                color: borderColor
                              }}
                            />

                            <Tag text={label} background={color} />
                          </Box>
                        )
                      }}
                    </RadioButtonGroup>
                  </Box>

                  <ErrorForm errorsList={postDataError.errors} fieldName='statusId' />
                </Box>
              </Box>

              <Box
                direction='row'
                pad={{ vertical: 'medium' }}
                justify='center'
                align='center'
                gap='small'
              >
                <Box width='xsmall'>
                  <Anchor
                    color='dark-3'
                    label='Cancelar'
                    onClick={toggleFormChangeStatusLayer}
                  />
                </Box>

                <Box width='small'>
                  <Button
                    color='brand'
                    primary
                    label={ !loadingPostForm ? 'Salvar' : 'Salvando...' }
                    disabled={ loadingPostForm }
                    onClick={() =>
                      this.handlePost({
                        id,
                        statusId,
                      })
                    }
                  />
                </Box>
              </Box>
            </Box>
          </Layer>
        )}
      </ResponsiveContext.Consumer>
    );
  }
}

const mapStateToProps = ({ layerReducer, formCreateUpdateCopyGeneric }) => ({
  id: formCreateUpdateCopyGeneric.id,
  name: formCreateUpdateCopyGeneric.name,
  protocolNumber: formCreateUpdateCopyGeneric.protocolNumber,
  currentStatusId: formCreateUpdateCopyGeneric.currentStatusId,
  statusId: formCreateUpdateCopyGeneric.statusId,

  loadingPostForm: formCreateUpdateCopyGeneric.loadingPostForm,
  postDataError: formCreateUpdateCopyGeneric.postDataError,

  toggleLayer: layerReducer.toggleLayer,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({
    changeValues,
    changeStatus,
    toggleFormChangeStatusLayer,
  }, dispatch);


export default connect(mapStateToProps, mapDispatchToProps)(FormChangeStatus);
