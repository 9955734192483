const selectCampaignTypeOptions = [
  {
    id: 1,
    name: 'E-mail',
    buy: true,
    searchCampaign: true,
    communication: false,
  },
  {
    id: 2,
    name: 'SMS',
    buy: true,
    searchCampaign: true,
    communication: true,
  },
  {
    id: 3,
    name: 'WhatsApp Web',
    buy: false,
    searchCampaign: false,
    communication: true,
  },
];

export default selectCampaignTypeOptions;
