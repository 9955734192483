import TYPES from './types';
import COMMON_TYPES from '../../../actions/types';
import * as analytics from '../../../analytics';

import profileStatus from '../../../constants/profile-status';

import { details } from '../../../api/common';
import { queryCommon } from '../../../api/queries';
import { getProfile } from '../../../api/client';
import { reourceHistory } from '../../../api/history';
import {
  getCommunicationType,
  getCommunicationTemplate,
  transactionCommunication,
  sendCommunication,
} from '../../../api/communication';

import { showNotificationLayer } from '../../../actions/notification';
import { formatFormShortMessage } from '../utils';

const loadingPost = () => ({ type: COMMON_TYPES.formPostWatingResponse });

export const sendShortMessage = params => {
  const {
    formParams,
    callbackSuccess,
  } = params;

  const values = formatFormShortMessage(formParams);

  return dispatch => {
    dispatch(loadingPost());

    sendCommunication(values)
      .then(() =>
      {
        dispatch(loadingPost());

        dispatch(
          showNotificationLayer('Mensagem programada para envio!')
        );
      })
      .then(() =>
      {
        const withDebit = values.typeId === 2;

        if(withDebit) {
          transactionCommunication({
            typeCommunicationId: 2,
            operation: "debit",
            typeUse: "individual_communication",
            amount: 1,
          });
        }
      })
      .then(() => {
        const isWhatsAppWeb = values.typeId === 3;

        if(isWhatsAppWeb) {
          const phone = values.telephone.replace(/\D/g, '');
          const message = encodeURIComponent(values.message);

          window.open(
            `https://web.whatsapp.com/send/?phone=55${phone}&text=${message}`, '_blank', 'noopener, noreferrer'
          );
        }
      })
      .then(() => { callbackSuccess(); })
      .catch(responseFail =>
      {
        dispatch(loadingPost());

        dispatch({
          type: COMMON_TYPES.formPostResultError,
          payload: responseFail.response.data,
        });
      })
  }
};

export const toggleFormShortMessageLayer = params => {
  if(params && params.id)
  {
    const { id } = params;

    return async dispatch => {
      analytics.registerEvent({
        category: analytics.categories.voter,
        action: analytics.actions.voterCommunication,
      });

      try {
        dispatch({
          type: COMMON_TYPES.toggleLayer, payload: 'sendShortMessage'
        });

        const [voter, profile, history] = await Promise.all([
          details({
            id,
            path: 'eleitor.php',
          }),
          getProfile(),
          reourceHistory({
            category: 'communication',
            resourceId: id,
            page: 1,
            limit: 5,
          }),
        ]);

        return dispatch({
          type: COMMON_TYPES.detailsGetResult,
          payload: {
            data: {
              voter: voter.data,
              profile: profile.data.data.find(a => a.status === profileStatus.accept && a.enabled) || null,
              history: history.data.data,
            }
          },
        });
      }
      catch (error) {
        return dispatch({ type: COMMON_TYPES.detailsGetResultError });
      }
    }
  }

  return [
    { type: COMMON_TYPES.toggleLayer, payload: null },
    { type: COMMON_TYPES.detailsGetResult, payload: { data: null } },
    { type: COMMON_TYPES.formClearValues },
    { type: TYPES.clearFormCommunication },
  ];
};

export const searchTemplates = params => {
  return dispatch => {
    queryCommon({ ac: 'templateList', params })
      .then(response =>
        dispatch({
          type: TYPES.templateList,
          payload: response.data.map(({ id, name, clientId }) => ({
            id,
            name,
            clientId,
          })),
        })
      )
  }
};

export const searchTemplateDetails = params => {
  return dispatch => {
    getCommunicationTemplate(params.id)
      .then(response =>
        dispatch({
          type: TYPES.templateDetails,
          payload: response.data,
        })
      )
      .catch(() => {
        dispatch({
          type: TYPES.templateDetails,
          payload: {},
        })
      })
  }
};

export const searchCredit = params => {
  return dispatch => {
    const { typeId } = params;

    if(parseInt(typeId) !== 2)
    {
      return dispatch({
        type: TYPES.creditCommunication,
        payload: 1,
      })
    }

    getCommunicationType({
      typeCommunicationId: typeId,
    })
    .then(response =>
      dispatch({
        type: TYPES.creditCommunication,
        payload: response.data.reduce((acc, currentValue) => acc + currentValue.amount, 0),
      })
    )
    .catch(() => {
      dispatch({
        type: TYPES.creditCommunication,
        payload: null,
      })
    })
  }
};
