import React from 'react';
import { Box, Text } from 'grommet';
import { Send } from 'grommet-icons';

const MessageTemplate = ({ type, template, templateId, values }) => {

  const message = template?.replace(/{{(.*?)}}/g, (p1, p2) =>
    values[p1] || values[p2]
  );

  return (
    <Box
      round='xsmall'
      border={{ color: '#f2eadd', size: 'xsmall' }}
      pad={{ horizontal: 'small', vertical: 'xsmall' }}
      background='#f2eadd'
    >
      {template !== null && templateId > 0 ? (
        <Box
          round='small'
          pad={{ horizontal: 'small', vertical: 'small' }}
          margin={{ bottom: 'xsmall', top: 'xlarge', left: 'large' }}
          background='light-1'
        >
          <Text size='small'>
            {message}
          </Text>
        </Box>
      ) : (
        <Box
          round='small'
          pad={{ horizontal: 'small', vertical: 'small' }}
          margin={{ bottom: 'xsmall', top: 'xlarge', left: 'large' }}
          background='light-1'
        >
          <Text size='small'>
            Selecione os demais campos e um modelo para visualizar como ficará :)
          </Text>
        </Box>
      )}

      <Box
        direction='row'
        round='medium'
        pad={{ horizontal: 'medium', vertical: 'small' }}
        margin={{ bottom: 'xsmall', top: 'xsmall' }}
        background='white'
        justify='between'
      >
        <Text size='small' color='light-6'>
          Mensagem
        </Text>

        <Send color='light-6' size='medium' />
      </Box>
    </Box>
  );
}

export { MessageTemplate };
