const selectCommunicationCategoryOptions = [
  { id: 1, name: 'Aniversário' },
  //{ id: 2, name: 'Cadastro atendimento' },
  //{ id: 3, name: 'Atualização atendimento' },
  { id: 4, name: 'Cadastro eleitor' },
  { id: 5, name: 'Atualização cadastral de eleitor' },
  { id: 6, name: 'Visita ao escritório' }
];

export default selectCommunicationCategoryOptions;
