const TYPES = ({

  // list
  loadingFormSearch: 'LOADING_SEARCH_SETTINGS_CATEGORY_FORM_TOGGLE',
  searchSuccess: 'SEARCH_SETTINGS_CATEGORY_SUCCESS',
  searchError: 'SEARCH_SETTINGS_CATEGORY_LIST_ERROR',

  // form create
  updateList: 'MANAGER_SETTINGS_CATEGORY_SUCCESS_UPDATE_LISTING',
  insertList: 'MANAGER_SETTINGS_CATEGORY_SUCCESS_INSERT_LISTING',
  deleteList: 'MANAGER_SETTINGS_CATEGORY_SUCCESS_DELETE_LISTING',

  // form search
  setDefaultPage: 'SET_SEARCH_SETTINGS_CATEGORY_DEFAULT_PAGE',
});

export default TYPES;
