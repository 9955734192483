import TYPES from './types';
import COMMON_TYPES from '../../../actions/types';
import * as analytics from '../../../analytics';

import { buyCommunication } from '../../../api/communication';

import selectCampaignCost from '../../../constants/select-campaign-cost';

const loadingPost = () => ({ type: COMMON_TYPES.formPostWatingResponse });

export const create = params => {
  return dispatch => {
    dispatch(loadingPost());

    buyCommunication(params.formParams)
      .then(({ data }) => {
        dispatch(loadingPost());
        dispatch({
          type: COMMON_TYPES.formPostResultSuccess,
          payload: data
        })
      })
      .catch(responseFail => {
        dispatch(loadingPost());
        dispatch({
          type: COMMON_TYPES.formPostResultError,
          payload: responseFail.response.data
        });
      })
  }
}

export const recalculate = params => {
  const { typeCommunicationId = 0, amount = 0 } = params ?? {};

  const findCost = (type, amount) => {
    return selectCampaignCost.find(item =>
      item.typeCommunicationId === type && amount >= item.amountMin && amount <= item.amountMax
    ) ?? {
      costUnit: 0,
      costBankSlip: 0,
    };
  }

  const calculateTotal = (costUnit, costBankSlip, amount) => (costUnit * amount) + costBankSlip

  const { costUnit, costBankSlip } = findCost(typeCommunicationId, amount);

  return {
    type: TYPES.recalculateResult,
    payload: {
      costUnit: costUnit,
      costBankSlip: costBankSlip,
      costTotal: calculateTotal(costUnit, costBankSlip, amount),
    }
  }
}

export const toggleBuyLayer = params => {
  if(params && params.id) {
    //ga
    analytics.registerEvent({
      category: analytics.categories.customerArea,
      action: analytics.actions.customerAreaBuyCredit,
    });

    return [
      { type: COMMON_TYPES.toggleLayer, payload: 'buyCommunicationBalance' },
      recalculate(),
    ]
  }

  return [
    { type: COMMON_TYPES.toggleLayer, payload: null },
    { type: COMMON_TYPES.formClearValues },
  ];
};
