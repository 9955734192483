import axiosGlobalInstance from '../http-instance';
import { getCookie, decryptAes } from '../../utils';

const getCommunicationType = (params) => {
  const userLoggedState = getCookie();
  const decipher = decryptAes(userLoggedState);

  return axiosGlobalInstance({
    method: 'GET',
    url: '/transaction.communication.php',
    params,
    headers: {
      token: decipher?.token?.value,
      action: 'getByTypeCommunication',
    }
  });
}

export default getCommunicationType;
